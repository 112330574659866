// import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
// import pic01 from '../assets/images/pic01.jpg'
// import pic02 from '../assets/images/pic02.jpg'
// import pic03 from '../assets/images/pic03.jpg'
// import pic04 from '../assets/images/pic04.jpg'
// import pic05 from '../assets/images/pic05.jpg'
// import pic06 from '../assets/images/pic06.jpg'
import Banner from '../components/Banner'
import Layout from '../components/layout'

import Aderant from '../assets/images/aderant.png'
import SqlServer from '../assets/images/microsoft-sql-server-logo.png'
import NetCore from '../assets/images/net-core.png'
import AmazonWebServices from '../assets/images/amazon-web-services.png'
import Angular from '../assets/images/angular.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Steel Ridge Partners"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>About Us</h2>
              </header>
              <p>
                Steel Ridge Partners is a leading software consulting and
                development company dedicated to providing our clients the best
                business solutions to fit their evolving needs. We help our
                clients achieve their goals through innovative product
                development and integration.
              </p>

              <p>
                Unlike some software companies, we excel at connecting with the
                people that use our products. Our goal is to listen to and
                understand clients' needs and provide recommendations on how to
                fulfill them. Complacency simply isn't part of our vocabulary -
                we are always hungry to take on the next challenge, especially
                if it continues to push us outside our comfort zone.
              </p>
              {/* <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="/landing"
                    className="button next"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul> */}
            </div>
          </section>

          {/* <hr className="major" /> */}

          <section id="two">
            <div className="inner section--white">
              <header className="major">
                <h2>Custom Software Development</h2>
              </header>
              <p>
                Steel Ridge Partners has extensive experience building custom
                web applications for law firms. We work hand-in-hand with our
                clients to solve their needs by building software and processes
                to maximize their potential. Together, we take ideas and work
                through the design, development, and implementation of new
                projects. We then provide documentation, training, and support
                for the applications over time. We always work with the latest
                technologies to build and design modern and intuitive
                applications that help optimize key business processes.
              </p>

              {/* <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="/landing"
                    className="button next"
                  >
                    Get Started
                  </Link>
                </li>
              </ul> */}
            </div>
          </section>

          <section id="two">
            <div className="inner section--white">
              <header className="major">
                <h2>Software Consulting</h2>
              </header>
              <p>
                One of our core operations is software consulting. Businesses
                need the right software systems to increase productivity,
                optimize workflow, and maximize profits. Steel Ridge Partners
                thoroughly researches and vets potential software solutions
                before presenting the best solutions for your specific business
                needs.
              </p>
              <p>
                Once the appropriate software is selected, we create a
                customized implementation plan to get the entire organization
                online with a seamless transition. Beyond the installation and
                data migration, we want to make sure we set up your organization
                for future success. We offer training sessions for management
                and employees, quick reference guides, and even customized
                training videos.
              </p>
              {/* <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="/landing"
                    className="button next"
                  >
                    Get Started
                  </Link>
                </li>
              </ul> */}
            </div>
          </section>
          <section id="two">
            <div className="inner section--white">
              <header className="major">
                <h2>Our Core Technologies</h2>
              </header>

              <div className="grid-wrapper">
                <div className="col-2">
                  <span className="image fit">
                    <img src={Angular} alt="" />
                  </span>
                </div>
                <div className="col-2">
                  <span className="image fit">
                    <img src={NetCore} alt="" />
                  </span>
                </div>
                <div className="col-2">
                  <span className="image fit">
                    <img src={SqlServer} alt="" />
                  </span>
                </div>
                <div className="col-2">
                  <span className="image fit">
                    <img src={AmazonWebServices} alt="" />
                  </span>
                </div>
                <div className="col-2">
                  <span className="image fit">
                    <img src={Aderant} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Software Development</h3>
                  <p>
                    Steel Ridge Partners has extensive experience building
                    applications in the legal and financial sectors. We help our
                    clients build, update, and maintain web-based applications.
                    The goal for every development is to design modern and
                    intuitive client facing applications that help automate
                    intricate key business processes.
                  </p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Tempus</h3>
                  <p>feugiat amet tempus</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Magna</h3>
                  <p>Lorem etiam nullam</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Ipsum</h3>
                  <p>Nisl sed aliquam</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Consequat</h3>
                  <p>Ipsum dolor sit amet</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/landing"
                className="link primary"
              >
                <header className="major">
                  <h3>Etiam</h3>
                  <p>Feugiat amet tempus</p>
                </header>
              </Link>
            </article>
          </section> */}
          {/* <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Massa libero</h2>
              </header>
              <p>
                Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
                libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
                Pellentesque condimentum sem. In efficitur ligula tate urna.
                Maecenas laoreet massa vel lacinia pellentesque lorem ipsum
                dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et
                sagittis libero. Mauris aliquet magna magna sed nunc rhoncus
                amet pharetra et feugiat tempus.
              </p>
              <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="/landing"
                    className="button next"
                  >
                    Get Started
                  </Link>
                </li>
              </ul>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
