import React from 'react'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Steel Ridge Partners</h1>
      </header>
      <div className="content">
        <p>
          Creating beautiful and user-friendly software that
          <br />
          grows alongside our clients' growing businesses
        </p>
        <ul className="actions">
          <li>
            <a
              href="#contact"
              name="Contact Us"
              className="button next scrolly"
            >
              Let's Start Building
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
